/**
 * Created by ApolloLogix on 2023-05-12.
 */

/*
fbq('track', 'subscribe',{
    'email': 'useremail',
    'time': 1234567890
});

fbq('track', 'Purchase', {
    value: 10.99,
    currency: 'USD',
    content_ids: ['PRODUCT_ID_1', 'PRODUCT_ID_2'],
    content_type: 'product',
    num_items: 2
});
*/


//console.log(unixTimestamp);

/*

fbq('track', "ViewContent", {
        "data": [
            {
                "event_name": "Purchase",
                "event_time": unixTimestamp,
                "action_source": "website",
                "user_data": {
                    "em": "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068",
                    "ph": "d36e83082288d9f2c98b3f3f87cd317a31e95527cb09972090d3456a7430ad4d"
                },
                "custom_data": {
                    "currency": "USD",
                    "value": "142.52"
                }
            }
        ]
    }
);

*/



/*

// Using DOMContentLoaded event
document.addEventListener('DOMContentLoaded', function () {
    // All elements are loaded and ready to be accessed and manipulated
    console.log('All elements are loaded');
});

*/


// Using window.onload event
/*

window.onload = function () {
    // All elements, including external resources like images and stylesheets, are loaded
    console.log('All elements, including external resources, are loaded');
    // Get all elements with the specified class
    const elements = document.getElementsByClassName('get_tickets');
    console.log('get_tickets elements', elements);
    // Iterate through each element
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];

        // Check if the element has an onclick event
        if (typeof element.onclick === 'function') {
            // Store the URL when the element is clicked
            element.addEventListener('click', function () {
                const url = element.href;
                // Do something with the URL (e.g., store it in a variable or send it to the server)
                console.log('get_tickets url', url);
            });
        }
    }

};
*/


function send_meta_conversion(url,event_name) {
    console.log("send_meta_conversion 2305222111 2305231304");
    // Get the current Unix timestamp
    const unixTimestamp = Math.floor(Date.now() / 1000);
    const data = {
        "data": [
            {
                "event_name": event_name,
                "event_time": unixTimestamp,
                "action_source": "website",
                "custom_data": {
                    "url": url,
                }
            }
        ]
    }
    fbq('track', "ViewContent", data);

    // now also send the data to the server
    var jsonData = JSON.stringify(data);
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "/meta_api/send_event/", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(jsonData);
    xhr.onload = function () {
        if (xhr.status === 200) {
            console.log("event send successfully. 2305222102 2305231304");
        } else {
            console.log("error sending event. 2305222102 2305231304");
        }
    };

}

let elements_get_tickets_buttons;

window.onload = function () {
    // All elements, including external resources like images and stylesheets, are loaded
    //console.log('All elements, including external resources, are loaded 2305231304');
    //set_onclick_events()
    // using $(document).ready(function () { for now
};


$(document).ready(function () {
    console.log('All elements, including external resources, are loaded 2305231304');
    console.log('version 2306121554');
    elements_get_tickets_buttons = document.getElementsByClassName('get_tickets');
    set_onclick_events()

});

elements[0].onclick = function () {
    console.log('yeah yeah onclick 2305231304');
}

function set_onclick_events(){
    const elements = elements_get_tickets_buttons
    console.log('get_tickets elements 2306271717', elements);
    // Iterate through each element
    for (let i = 0; i < elements.length; i++) {
        console.log('onclick before assignment 2305231304:', elements[i].onclick);
        const element = elements[i];
        element.onclick = function () {
            const url = element.href;
            // Do something with the URL (e.g., store it in a variable or send it to the server)
            console.log('get_tickets url 2305231304', url);

            const event_name = element.getAttribute('data-event-name');
            console.log('get_tickets event_name 2305231304', event_name);

            send_meta_conversion(url,event_name)
        };
        console.log('onclick 2305231304:', elements[i].onclick);
    }
    console.log('get_tickets elements after setting onclick 2305231304', elements);
    console.log('get_tickets element[0].onclick after setting onclick 2305231304 2305231332', elements[0].onclick);
}

